@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/Pretendard-Bold.woff2') format('woff2'),
    url('assets/font/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/Pretendard-Medium.woff2') format('woff2'),
    url('assets/font/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard';
  src: url('assets/font/Pretendard-Regular.woff2') format('woff2'),
    url('assets/font/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
